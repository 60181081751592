import React from 'react'
import Layout from '../../components/layout'
import EllisHelmet from '../../components/EllisHelmet'
import LocationMenu from '../../components/location-specific/LocationMenu'
import EventsPage from '../../components/location-specific/EventsPage'

class EventsDowntown extends React.Component
{
	render()
	{
		const location = "downtown";
		const currentPage = "events";
		const title = "Private Events";

		return (
			<Layout className="menuPage" location={location} currentPage={currentPage}>
				<EllisHelmet page={currentPage} title={title} location={location} />
				<LocationMenu location={location} currentPage={currentPage} />
				<EventsPage location={location} />
			</Layout>
		)
	}
}

export default EventsDowntown