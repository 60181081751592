import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Menu from '../Menu'
import { formatDatoCMSData } from '../../services/datoCMS.service'
import { locationData } from '../../assets/locationData.js'

export default function EventsPage(props) {
  const query = useStaticQuery(graphql`
  query eventsQuery {
    allDatoCmsPrivateEvent {
      nodes {
        phone
        email
        packages {
          name
          price
          details
        }
        location {
          bryant
          downtown
        }
      }
    }
  }`);

  const eventsData = formatDatoCMSData(query);

  const location = locationData.getLocationData(props.location);

  // Our events are built from our regular menu items
  const events = eventsData.nodes.map((event) => {
    // If we are requesting location-specific menu items, check for that here
    if (props.location) {
      // If we got here, our current location is NOT selected for this menu item! Skip it!
      if (event.locations != null && !event.locations.includes(props.location))
        return null;
    }

    let packages = [];

    // NOTE: package is a reserved word in React or Gatsby
    for (const pkg of event.packages) {

      let details = [];

      for (const detail of pkg.details.split("\n")) {
        details.push(<li>{detail}</li>);
      }

      packages.push(<React.Fragment>
        <h3>{pkg.name}</h3>
        <h4>{pkg.price}</h4>
        <ul>
          {details}
        </ul>
      </React.Fragment>);
    }

    return (<React.Fragment key={event.name}>
      <p>Rent part or all of the {location.name} location for your event! Perfect for parties, meetings, fundraisers, and more.</p>
      {packages}
      <h2>Book Your Event Today!</h2>
      <p>Call {event.phone} or e-mail <a href={"mailto:" + event.email}>{event.email}</a></p>
    </React.Fragment>
    );
  });

  return (
    <div id="privateevents" className="wrapper style1">
      <div className="container">
        <Menu>
          <header className="major">
            <h2>Private Events</h2>
          </header>
          <div className="box alt">
            <div className="row">
              <div className="col-8 col-12-xsmall">
                {events}
              </div>
            </div>
          </div>
        </Menu>
      </div>
    </div>
  )
}
